<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-dept type="search" :isFirstValue="false" name="deptCd" v-model="searchParam.deptCd" />
        </div>
      </template>
    </c-search-box>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="부서별 유해인자 관리 목록"
        :merge="grid.merge"
        :columns="grid.columns"
        selection="multiple"
        rowKey="deptCd"
        :data="grid.data"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="LBLADD" icon="add" @btnClicked="addDept" v-if="editable" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeDept" v-if="editable" />
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'hazardNames'">
            <q-btn rounded size="xs" class="tab-add-btn" color="orange-6" icon="add" label="추가"
              @click.prevent="addHazard(props.row)"/>
            &nbsp;
            <q-chip
              v-for="(hazard, index) in props.row.hazardList"
              style="top:3px;"
              :key="index"
              outline square 
              :removable="editable"
              color="primary"
              text-color="white"
              class="q-ma-none customchipdept"
              @remove="removeHazard(props.row, hazard)">
              {{hazard.hazardName}}
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'harmful-factors-dept-manage',
  props: {
    param: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
      },
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
          { index: 1, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'upDeptName',
            field: 'upDeptName',
            label: '팀',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '반',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '유해인자',
            align: 'left',
            type: 'custom',
            style: 'width: 400px',
            sortable: false,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
      hazardSaveUrl: '',
      hazardDeleteUrl: '',
      deleteUrl: '',
      isSave: false,
      saveType: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      saveRow: {},
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.hazard.dept.list.url;
      this.saveUrl = transactionConfig.mdm.dept.hazardUpdate.url;
      this.hazardSaveUrl = transactionConfig.sai.hazard.dept.save.url;
      this.deleteUrl = transactionConfig.sai.hazard.dept.delete.url;
      this.hazardDeleteUrl = transactionConfig.sai.hazard.dept.hazardDelete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addDept() {
      this.popupOptions.title = '유해인자 취급부서 추가';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { deptCd: item.deptCd }) === -1) {
            saveData.push({
              hazardFlag: 'Y',
              deptCd: item.deptCd,
            });
          }
        });

        this.$http.url = this.saveUrl;
        this.$http.type = 'PUT';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '저장하시겠습니까?<br/>*기준정보가 변경될 경우 입력중인 계획의</br>부담작업 평가결과 갱신이 필요합니다.',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeDept() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addHazard(row) {
      this.saveRow = row;
      this.popupOptions.title = '유해인자 검색'; // 유해인자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/wmr/harmfulFactorsPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let saveData = [];
          if (this.$_.findIndex(this.saveRow.hazardList, { hazardCd: _item.hazardCd }) === -1) {
            saveData.push({
              hazardCd: _item.hazardCd,
              deptCd: this.saveRow.deptCd,
            });
          }

          this.$http.url = this.hazardSaveUrl;
          this.$http.type = 'POST';
          this.$http.param = saveData;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          });
        })
      }
    },
    removeHazard(row, hazard) {
      this.$http.url = this.$format(this.hazardDeleteUrl, hazard.hazardCd, row.deptCd);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        row.hazardList = this.$_.reject(row.hazardList, hazard);
      });
    },
  }
};
</script>
